<template>
    <div>
        <div id="g_id_onload"
            data-client_id="864430945659-u5cl337nvam2vg200bt23d0c2t5ofdk7.apps.googleusercontent.com"
            data-context="signin"
            data-ux_mode="popup"
            data-login_uri="https://reckon.auth.us-east-1.amazoncognito.com/oauth2/token?client_id=7qd232h77i05eaicn3o735ec82&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fwereckon.com"
            data-auto_prompt="false">
        </div>

        <div class="g_id_signin"
            data-type="standard"
            data-shape="rectangular"
            data-theme="filled_black"
            data-text="signin_with"
            data-size="large"
            data-logo_alignment="left">
        </div>
    </div>
</template>

<script>
export default {
  name: 'GoogleSignInButton',
  data: () => ({}),
};
</script>
