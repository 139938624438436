<template>
  <div class="mb-6">
    <div class="ml-6 my-5 text-h3 text-md-h2"
    >
      Capabilities
    </div>
    <div class="mx-6 mx-md-15 text-h5">
      <div>
        <div class="text-section">
        Reckon delivers information technology and staff augmentation services to the federal government, supporting programs and missions critical to national security.  We bring fast, reliable, immediately productive, and experienced resources to ensure customer satisfaction as we tackle their toughest problems.  Our team of highly skilled and experienced engineers possess a broad range of technical capabilities and are proficient, but not limited to the following areas:
        </div>
        <div style="margin-top: 50px" class="caps">
          <div>
            <div class="text-h4 grey--text pl-5" style="border-left: 5px solid red">Systems Engineering</div>
            Our Systems Engineers design, develop, and maintain complex systems, ensuring that they function effectively and efficiently. Their primary focus is on the big picture, looking at how different components and subsystems work together to achieve a specific goal.
          </div>
          <div>
            <div class="text-h4 grey--text pl-5" style="border-left: 5px solid red">Application Development</div>
            Our Application Developers design, create, and maintain software applications for various platforms, such as mobile devices, desktop computers, web browsers, and more. Their work involves a range of activities to develop applications that can perform specific functions or provide solutions for users.
          </div>
          <div>
            <div class="text-h4 grey--text pl-5" style="border-left: 5px solid red">Software Testing</div>
             Our software testers, play a crucial role in the software development process. Their primary responsibility is to ensure that software applications are free of defects, perform as intended, and meet the quality standards and requirements set by the project stakeholders.
          </div>
          <div>
            <div class="text-h4 grey--text pl-5" style="border-left: 5px solid red">Cyber Security</div>
             Our Cyber Security Engineers design, implement, and maintain security measures to protect computer systems, networks, and data from various threats, including cyberattacks, unauthorized access, and data breaches. Their role is critical in ensuring the confidentiality, integrity, and availability of information in today's digital world.
          </div>
          <div>
            <div class="text-h4 grey--text pl-5" style="border-left: 5px solid red">Cloud Engineering</div>
             Our Cloud Engineers design, implement, and maintain cloud-based solutions and services. They work with cloud platforms and technologies to ensure that organizations can leverage the benefits of cloud computing, including scalability, flexibility, and cost efficiency.
          </div>
          <div>
            <div class="text-h4 grey--text pl-5" style="border-left: 5px solid red">Dev Ops</div>
             Our Dev Ops Developers play a critical role in streamlining and automating the software development and deployment processes. DevOps engineers aim to improve the efficiency, reliability, and scalability of software development and delivery.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CapabilitiesView',
  data: () => ({}),
};
</script>
<style>
div.caps > div > div {
  margin: 60px 0 20px 0;
  font-size: larger;
  font-weight: bold;
}
</style>
