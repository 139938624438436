<template>
  <div class="text-section ma-6 ma-md-16 definition">
    <audio
      id="audio-player"
      hidden
    >
      <source src="../assets/reckon.mp3" type="audio/mpeg">
      <track kind="captions">
    </audio>
    <!-- <v-row> -->
    <v-item-group>
      <v-container>
        <v-row class="d-block">
          <v-row class="d-inline-block">
            <v-row class="align-baseline mb-2">
              <div class="text-h3 text-md-h2 text-lg-h1 mr-3 definition-header">reckon</div>
              <h6 class="text-h5 text-md-h4 text-lg-h3 definition-header">verb</h6>
            </v-row>
            <v-row class="align-center text-h6 text-md-h5 text-lg-h4">
              <span class="syllables mr-6">reck-on</span>
              <a class="syllables-audio rounded-pill pa-3" @click="playAudio()"
                @keydown="handleKeydown($event)">
                ˈre-kən
                <v-icon>mdi-volume-high</v-icon>
              </a>
            </v-row>
            <v-row class="align-center text-h6 text-md-h5 text-lg-h4 mt-6">
              <span class="syllables mr-6">reckoned; reckoning</span>
            </v-row>
            <v-row class="text-h6 text-md-h5 text-lg-h4 mt-6">
              <p><strong>1.</strong> To believe something is true.</p>
            </v-row>
            <v-row class=" text-h6 text-md-h5 text-lg-h4 left-border">
              <!-- <v-divider vertical class="definition-divider mr-3"></v-divider> -->
              <span>We <i>reckon</i> there is no better company.</span>
            </v-row>
            <v-row class="text-h6 text-md-h5 text-lg-h4 mt-6">
              <p><strong>2.</strong> To make a calculation.</p>
            </v-row>
            <v-row class=" text-h6 text-md-h5 text-lg-h4 left-border">
              <!-- <v-divider vertical class="definition-divider mr-3"></v-divider> -->
              <span>We <i>reckoned</i> that it was time for a change.</span>
            </v-row>
            <v-row class="mt-9">
              <p class="text-h5 text-md-h4 text-lg-h3 definition-header">Phrases</p>
            </v-row>
            <v-row>
              <v-divider class="definition-divider"></v-divider>
            </v-row>
            <v-row class="text-h6 text-md-h5 text-lg-h4 mt-8">
              <strong>to be reckoned with</strong>
            </v-row>
            <v-row class="text-h6 text-md-h5 text-lg-h4">
              <p>Something of considerable importance or ability that is not to be ignored or
                underestimated.</p>
            </v-row>
            <v-row class="text-h6 text-md-h5 text-lg-h4 left-border">
              <!-- <v-divider vertical class="definition-divider mr-3"></v-divider> -->
              <span>In our industry, we will become a force <i>to be reckoned with</i>.</span>
            </v-row>
          </v-row>
        </v-row>
      </v-container>
    </v-item-group>
    <!-- </v-row> -->
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',

  data: () => ({}),
  methods: {
    playAudio() {
      const audio = document.getElementById('audio-player');
      audio.play();
    },
    handleKeydown(event) {
      if (event.keyCode === 13) {
        this.playAudio();
      }
    },
  },
};
</script>
<style scoped>
.text-h3, .text-h2, .text-h1 {
  font-family: unset;
}

.left-border {
  border-left: 4px solid red;
  padding-left: 10px;
}

.definition-header {
  font-family: "Playfair Display", serif !important;
}

.definition {
  /* scale: .8; */
}

.definition-divider {
  border-color: red !important;
  border-width: 2px;
}

.syllables {
  color: rgb(131, 131, 131);
}

.syllables-audio {
  background-color: #333;
}

.font-size {
  font-size: 36px;
}

.slogan {
  font-family: "Oswald", serif;
  color: white;
  display: flex;
  width: 100%;
}

.slogan div {
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
}

.v-slide-group {
  background: black;
}

.reckon-card {
  font-family: Oswald, serif;
  font-size: 1.4rem;
  text-align: center;
  width: -webkit-fill-available;
  border-radius: 25px;
  /* border: 2px solid red */
}

.reckon-slide {
  border: 2px solid red;
  /* background-image: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5) )
    , url("../assets/action.png"); */
  background-size: cover;
}
</style>
