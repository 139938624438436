<template>
  <v-container>
    <div class="fade"></div>
    <div class="star-wars">
      <div class="crawl">
        <div class="crawl-title">
          <p>Episode II</p>
          <h1>A New Reckoning</h1>
        </div>
        <p class="pb-12">In a galaxy not so far away, in a time when the world was gripped by a once in a generation pandemic, a group of visionary minds embarked on a journey to create something extraordinary.  They banded together and formed Reckon.</p>
        <p class="pb-12">These Founders hailed from diverse backgrounds and possessed a wide range of technical skills, but they shared a common dream: to redefine the way people connected and communicated. They saw the potential for a company that would shape the future of technology and transform the way humans interacted with the digital world.</p>
        <p>And so, the company's story continues to unfold, with each day bringing new challenges and new triumphs. The legacy of The Founders lives on, inspiring future generations to dream, create, and boldly go where no one has gone before.</p>
      </div>
    </div>
  </v-container>
</template>
<script>
export default {
  name: 'BeginningsView',
  data: () => ({}),
};
</script>
<style>
  .star-wars {
    /* Flexbox to center the entire element on the screen */
    display: flex;
    justify-content: center;
    /* This is a magic number based on the context in which this snippet is used and effects the perspective */
    height: 200px;
    /* This sets allows us to transform the text on a 3D plane, and is somewhat a magic number */
    perspective: 400px;
    /* The rest is totally up to personal styling preferences */
    color: #feda4a;
    font-family: 'Pathway Gothic One', sans-serif;
    font-size: 350%;
    font-weight: 600;
    /* letter-spacing: 6px; */
    line-height: 150%;
    text-align: justify;
  }
  @media (max-width: 750px) {
    .star-wars {
      font-size: 300%;
    }
  }
  .crawl {
    /* Position the element so we can adjust the top property in the animation */
    position: relative;
    /* Defines the skew origin at the very center when we apply transforms on the animation */
    transform-origin: 50% 100%;
    /* Adds the crawl animation, which plays for one minute */
    animation: crawl 60s linear infinite;
    top: 99999px;
  }
  .crawl > .crawl-title {
    font-size: 90%;
    text-align: center;
  }
  .crawl > .crawl-title h1 {
    margin: 0 0 100px;
    text-transform: uppercase;
  }
  @keyframes crawl {
    0% {
      /* The element starts below the screen */
      top: 300px;
      /* Rotate the text 20 degrees but keep it close to the viewer */
      transform: rotateX(20deg) translateZ(0);
    }
    100% {
      /* This is a magic number, but using a big one to make sure the text is fully off the screen at the end */
      top: -6000px;
      /* Slightly increasing the rotation at the end and moving the text far away from the viewer */
      transform: rotateX(25deg) translateZ(-2500px);
    }
  }
  .fade {
    position: relative;
    width: 100%;
    min-height: 60vh;
    top: -25px;
    background-image: linear-gradient(0deg, transparent, black 75%);
    z-index: 1;
  }
</style>
