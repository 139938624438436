<template>
    <google-sign-in-button></google-sign-in-button>
</template>

<script>
import GoogleSignInButton from '@/components/GoogleSignInButton.vue';

export default {
  name: 'LoginView',
  components: {
    GoogleSignInButton,
  },
  data: () => ({}),
};

</script>
